<template>
  <div class="page page--post page--opaque" :class="post !== null ? `page--${post.platform}` : ''">
    <Header />

    <div class="page-content" v-if="post">
      <h2>{{ getCountryFromCode(post.territory) }}</h2>
      
      <!-- Some of the embeds take a while to initialize, as a quick workaround
      we will show a loading screen for a certain time depending on the embed type -->
      <transition v-if="isLoading" name="page-transition">
        <Loader/>
      </transition>

      <YoutubeEmbed v-if="post.platform === 'youtube'" :id="post.platform_id" />
      <InstagramEmbed
        v-if="post.platform === 'instagram'"
        :id="post.platform_id"
        :embed="post.embed"
      />
      <TwitterEmbed
        v-if="post.platform === 'twitter'"
        :id="post.platform_id"
        :embed="post.embed"
      />
      <TikTokEmbed
        v-if="post.platform === 'tiktok'"
        :id="post.platform_id"
        :embed="post.embed"
      />
    </div>

    <!-- Next post -->
    <router-link
      v-if="nextPost"
      :to="`/post/${nextPost}`"
      class="next-link button button--next"
      >Next Post</router-link
    >
    <!-- More posts / Back to globe -->
    <router-link v-if="!nextPost" to="/" class="next-link button button--next"
      >More Posts</router-link
    >
  </div>
</template>
<script>
import Header from "../partials/Header.vue";
import Loader from "../partials/Loader.vue";

import YoutubeEmbed from "../partials/embeds/YoutubeEmbed.vue";
import InstagramEmbed from "../partials/embeds/InstagramEmbed.vue";
import TwitterEmbed from "../partials/embeds/TwitterEmbed.vue";
import TikTokEmbed from "../partials/embeds/TikTokEmbed.vue";

export default {
  props: ["posts", "countries"],
  data() {
    return {
      post: null,
      territory: null,
      nextPost: null,
      isLoading: true
    };
  },
  components: {
    Header,
    Loader,
    YoutubeEmbed,
    InstagramEmbed,
    TwitterEmbed,
    TikTokEmbed,
  },
  methods: {
    getCountryFromCode(code) {
      return this.countries.find(country => code === country.Code).Name;
    },
    handleLoader() {
      let timeout = 1000
      
      switch (this.post.platform) {
        case 'tiktok':
          timeout = 3000
      }

      setTimeout(() => {
        console.log('isLoading = false')
        this.isLoading = false
      }, timeout)
    }
  },
  mounted() {
    const id = this.$route.params.id;

    // Find the current region
    const region = Object.values(this.posts).find((region) =>
      region.posts.find((post) => {
        return parseInt(post.id) === parseInt(id);
      })
    );

    // Find the current post in region
    this.post = region.posts.find((post) => parseInt(post.id) === parseInt(id));
    this.territory = this.post.territory;
    
    // Do we have another post in this region?
    if (
      region.posts.length > 1 &&
      this.post.id !== region.posts[region.posts.length - 1].id
    ) {
      const currentPostIndex = region.posts.findIndex(
        (post) => parseInt(post.id) === parseInt(id)
      );
      const nextPostIndex = currentPostIndex + 1;
      this.nextPost = region.posts[nextPostIndex].id;
    }

    this.handleLoader()
  },
};
</script>