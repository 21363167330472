<template>
  <div class="embed-youtube">
    <div class="youtube-embed">
      <iframe
        width="560"
        height="315"
        :src="`https://www.youtube.com/embed/${this.id}`"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    id: String
  }
}
</script>