<template>
  <div class="menu">
    <div
      class="menu__backdrop"
      @click="closeSidebarPanel"
      v-if="isMenuOpen"
    ></div>
    <transition name="slide">
      <div v-if="isMenuOpen" class="menu__panel container">
        <div class="menu__close" @click="closeSidebarPanel">
          <span class="icon-hd-close"></span>
        </div>

        <div class="menu__links">
          <div class="menu__item">
            <p class="menu__title">Buy/Stream</p>
            <a
              target="_blank"
              href="https://nirvana.lnk.to/Nevermind30thWE"
              rel="noopener noreferrer"
              class="button button--dark button--work"
            >
              Nevermind 30th<br />anniversary
            </a>
          </div>

          <div class="menu__item">
            <p class="menu__title">Newsletter</p>
            <a
              target="_blank"
              href="https://digital.umusic.com/nirvana-microsite-email-signup"
              rel="noopener noreferrer"
              class="button button--dark button--newsletter"
            >
              Sign Up
            </a>
          </div>

          <div class="menu__item menu__share">
            <p class="menu__title">Share</p>
            <div class="menu__icons">
              <a
                class="link link--highlighted link--small"
                v-for="share in shares"
                :key="share.type"
                :href="share.url"
                target="_blank"
              >
                <span :class="['icon-' + share.type]"></span>
              </a>
              <a
                class="link link--highlighted link--small"
                @click.prevent="copyurl()"
                target="_blank"
                v-if="supportsCB"
              >
                <span class="icon-link"></span>
                <transition name="appear">
                  <div v-if="copied == true" class="link__message">Copied!</div>
                </transition>
              </a>
            </div>
          </div>

          <div class="menu__item menu__social">
            <p class="menu__title">Follow Nirvana</p>
            <div class="menu__icons">
              <a
                target="_blank"
                href="https://open.spotify.com/artist/6olE6TJLqED3rqDCT0FyPh"
                class="link link--highlighted link--small"
              >
                <span class="icon-spotify"></span>
              </a>
              <a
                target="_blank"
                href="https://www.facebook.com/Nirvana"
                class="link link--highlighted link--small"
              >
                <span class="icon-facebook"></span>
              </a>
              <a
                target="_blank"
                href="https://music.apple.com/us/artist/nirvana/112018"
                class="link link--highlighted link--small"
              >
                <span class="icon-apple"></span>
              </a>
              <a
                target="_blank"
                href="https://twitter.com/Nirvana"
                class="link link--highlighted link--small"
              >
                <span class="icon-twitter"></span>
              </a>
              <a
                target="_blank"
                href="https://www.youtube.com/user/NirvanaVEVO"
                class="link link--highlighted link--small"
              >
                <span class="icon-youtube"></span>
              </a>
            </div>
          </div>
        </div>

        <div class="menu__item">
          <a
            target="_blank"
            href="https://nirvana.com/"
            rel="noopener noreferrer"
            class="nirvana-link button button--dark button--work"
          >
            Nirvana.com
          </a>
        </div>

        <div class="menu__footer">
          <a
            href="https://privacy.umusic.com/"
            target="_blank"
            rel="noopener noreferrer"
            class="footer-item"
            >Privacy Policy</a
          >
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import UnderlinedText from "./UnderlinedText.vue";

export default {
  components: {
    UnderlinedText,
  },
  data() {
    return {
      copied: false,
      shares: [
        // TO DO: GET THESE DYNAMICALLY
        {
          type: "facebook",
          title: "Facebook",
          url:
            "https://www.facebook.com/sharer/sharer.php?u=" +
            window.location.origin,
        },
        {
          type: "twitter",
          title: "Twitter",
          url:
            "https://twitter.com/intent/tweet?text=" + window.location.origin,
        },
      ],
      supportsCB: false,
    };
  },
  created() {
    if (navigator.clipboard) {
      this.supportsCB = true;
    }
    this.$store.commit("CLOSE_NAV");
  },
  computed: {
    isMenuOpen() {
      return this.$store.getters["getNavState"];
    },
  },
  methods: {
    closeSidebarPanel() {
      this.$store.commit("TOGGLE_NAV");
    },
    copyurl() {
      navigator.clipboard.writeText(window.location.origin);
      this.copied = true;
      setTimeout(() => {
        this.copied = false;
      }, 2300);
    },
  },
  watch: {
    isMenuOpen(val) {
      if (val) {
        document.body.classList.add("no-scroll");
      } else {
        document.body.classList.remove("no-scroll");
      }
    },
  },
};
</script>