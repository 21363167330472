<template>
  <header v-if="$route.name != 'PreviewLetter'" class="header container-fluid" :class="this.$route.name === 'SendLetter' && 'header--hidden'">

    <div class="header__logo">
      <router-link to="/">
        <img src="../../assets/images/logo.svg" />  
      </router-link>
    </div>

    <div class="header__links">
      <router-link class="header__link" :class="this.$route.name === 'Home' && 'header__link--active'" to="/">
        <span>Globe</span>
      </router-link>
      <router-link class="header__link" :class="this.$route.name === 'Info'  && 'header__link--active'" to="/info">
        <span>Info</span>
      </router-link>
      <Burger/>
    </div>

  </header>
</template>

<script>
  import Burger from './Burger.vue';

  export default {
    components: {
      Burger
    },
    mounted() {

    }
  };
</script>