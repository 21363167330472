<template>
  <div class="loader">
    <img class="loader__logo" src="../../assets/images/loader-logo.svg" /> 
    <img class="loader__spinner" src="../../assets/images/spinner.png" /> 
  </div>
</template>

<script>
  export default {

  };
</script>