import Vue from 'vue';
import Router from 'vue-router';

// Components

import Home from '../components/pages/Home.vue';
import Info from '../components/pages/Info.vue';
import Post from '../components/pages/Post.vue';
import PageNotFound from '../components/pages/PageNotFound.vue';
import Privacy from '../components/pages/Privacy.vue';

Vue.use(Router);

const router = new Router({
  routes: [
    {
      path: '/',
      name: 'Home',
      component: Home,
    },
    {
      path: '/info',
      name: 'Info',
      component: Info,
    },
    {
      path: '/post/:id',
      name: 'Post',
      component: Post,
    },
    {
      path: '/privacy-policy',
      name: 'Privacy',
      component: Privacy
    },
    { 
      path: "*", 
      component: PageNotFound 
    }
  ],
  mode: 'history',
  base: '',
});

router.afterEach((to) => { // (to, from)
  // Add a body class specific to the route we're viewing
  let body = document.querySelector('body');

  const slug = !(to.params.postSlug)
    ? to.params.pageSlug
    : to.params.postSlug;
  body.classList.add('vue--page--' + slug);
});

export default router;