<template>
  <div class="burger" :class="{ 'active' : isBurgerActive }" @click.prevent="toggleBurger">
    <slot>
      <button type="button" class="burger__button" title="Menu">
        <span class="burger__bar burger__bar--1"></span>
        <span class="burger__bar burger__bar--2"></span>
        <span class="burger__bar burger__bar--3"></span>
      </button>
    </slot>
  </div>
</template>

<script>
  export default {
    computed: {
      isBurgerActive() {
        return this.$store.getters['getNavState'];
      }
    },
    methods: {
      toggleBurger() {
        this.$store.commit('TOGGLE_NAV');
      }
    }
  }
</script>
