<template>
  <div class="embed-instagram">
    <div v-html="embed"></div>
  </div>
</template>

<script>
export default {
  props: {
    id: String,
    embed: String
  },
  mounted() {
    window.instgrm.Embeds.process();
  }
}
</script>