<template>
  <div class="embed-tiktok">
    <div v-html="embed" ref="embed"></div>
  </div>
</template>

<script>
export default {
  props: {
    id: String,
    embed: String
  },
  beforeCreate() {
    const tiktokEmbedScript = document.createElement('script')
    tiktokEmbedScript.setAttribute('src', 'https://www.tiktok.com/embed.js')
    tiktokEmbedScript.setAttribute('id', 'tiktok-embed-script')
    document.head.appendChild(tiktokEmbedScript)
  },
  beforeDestroy() {
    const script = document.querySelector('#tiktok-embed-script')
    console.log(script)
    script.parentNode.removeChild(script)
  }
}
</script>