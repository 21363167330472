import * as types from '../mutation-types';

const state = {
  isNavOpen: false,
};

const mutations = {
  [types.TOGGLE_NAV] (state) {
    state.isNavOpen = !state.isNavOpen
  },
  [types.CLOSE_NAV] (state) {
    state.isNavOpen = false
  },
};

const getters = {
	getNavState: state => {
    return state.isNavOpen;
	},
}

export default {
  state,
  mutations,
  getters,
};