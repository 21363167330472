<template>
  <div class="embed-twitter">
    <div v-html="embed"></div>
  </div>
</template>

<script>
export default {
  props: {
    id: String,
    embed: String
  },
  mounted() {
    window.twttr.widgets.load()
  }
};
</script>