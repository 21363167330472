<template>
  <div class="page page--page-not-found page-not-found">
    <div class="container container--narrow">
      <router-link class="page-not-found__close" to="/">
        <span class="icon-close"></span>
      </router-link>
      <div class="page-not-found__text">
        Sorry, we can't find that page! <router-link to="/">Go back to homepage</router-link>.
      </div>
    </div>
  </div>
</template>
<script>
  export default {

  };
</script>
