<template>
  <div class="page page--info page--opaque">
    <Header/>
    <Info/>
  </div>
</template>
<script>

  import Info from '../partials/Info.vue';
  import Header from '../partials/Header.vue';

  export default {
    components: {
      Info,
      Header
    },
  };
</script>