<template>
  <div class="app">
    <transition
      v-if="isLoading != false && !$route.query.accessToken"
      name="page-transition"
    >
      <Loader />
    </transition>
    <transition name="app-backdrop-transition">
      <div v-if="this.$route.name != 'Home'" class="app__backdrop"></div>
    </transition>

    <Menu />

    <div class="app__content mx-auto">
      <Globe
        v-if="phase > 1"
        :regions="posts"
        @update:loading="isLoading = $event"
        :isLoading="isLoading"
        :inBackground="
          this.$route.name !== 'Home' || $store.getters.getNavState
        "
      />

      <Info v-if="phase == 1 && $route.name === 'Home'" />

      <transition
        v-if="isLoading == false || $route.query.accessToken"
        name="page-transition"
        appear
      >
        <!-- Make posts available on every page. Bypassing the need for complex vuex logic -->
        <router-view
          :key="this.$route.path"
          :posts="
            this.$route.path === '/info' ||
            this.$route.path === '/privacy-policy'
              ? null
              : posts
          "
          :countries="
            this.$route.path === '/info' ||
            this.$route.path === '/privacy-policy'
              ? null
              : countries
          "
        ></router-view>
      </transition>
    </div>

    <Footer />
  </div>
</template>

<script>
import Menu from "./components/partials/Menu.vue";
import Footer from "./components/partials/Footer.vue";

import Info from "./components/partials/Info.vue";
import Globe from "./components/partials/Globe.vue";
import Loader from "./components/partials/Loader.vue";

import posts from "@/data/posts.json";
import latlng from "@/data/latlng.json";
import countries from "@/data/countries.json";
import states from "@/data/states.json";

export default {
  data() {
    return {
      phase: 2,
      isLoading: null, // null not started, true = started, false = loaded
      posts: null,
      countries: null,
    };
  },

  created() {
    this.posts = this.postsByRegion;
    this.countries = countries;
  },

  computed: {
    postsByRegion() {
      return posts.reduce((accumulator, post) => {
        if (!accumulator.hasOwnProperty(post.territory)) {
          console.log(post.territory);

          accumulator[post.territory] = {
            latitude: latlng[post.territory.toLowerCase()][0],
            longitude: latlng[post.territory.toLowerCase()][1],
            posts: [post],
          };
        } else {
          accumulator[post.territory].posts.push(post);
        }
        return accumulator;
      }, {});
    },
  },

  mounted() {
    this.preventScroll();
    this.onResize();
    window.addEventListener("resize", this.onResize);
  },

  components: {
    Footer,
    Menu,
    Info,
    Globe,
    Loader,
  },

  watch: {
    $route() {
      this.preventScroll();
    },
  },

  methods: {
    preventScroll() {
      if (this.$route.name != "Home") {
        document.body.classList.add("no-scroll");
      } else {
        document.body.classList.remove("no-scroll");
      }
    },
    onResize() {
      let timeoutResizeBounce;
      clearTimeout(timeoutResizeBounce);
      timeoutResizeBounce = setTimeout(function () {
        const windowHeight = window.innerHeight;
        document.documentElement.style.setProperty(
          "--viewport-height",
          windowHeight + "px"
        );
      }, 200);
    },
  },
};
</script>
