"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var THREE = require("three");
function hashFunc(func) {
    return func
        .toString()
        .split('')
        .reduce(function (a, b) {
        a = (a << 5) - a + b.charCodeAt(0);
        return a & a;
    }, 0);
}
var Raycaster = /** @class */ (function () {
    function Raycaster(camera, element) {
        this.listeners = {};
        this.raycaster = new THREE.Raycaster();
        this.mouse = new THREE.Vector2();
        this.camera = camera;
        this.element = element;
    }
    Raycaster.prototype.raycast = function (event, object3D, handler) {
        this.mouse.x = (event.clientX / this.element.clientWidth) * 2 - 1;
        this.mouse.y = -(event.clientY / this.element.clientHeight) * 2 + 1;
        this.raycaster.setFromCamera(this.mouse, this.camera);
        // const intersection: object = this.raycaster.intersectObjects( [object3D] )[0];
        var intersection = this.raycaster.intersectObject(object3D)[0];
        if (intersection) {
            handler(event, intersection);
        }
    };
    Raycaster.prototype.on = function (eventName, object3D, handler) {
        var _this = this;
        if (!this.listeners[eventName])
            this.listeners[eventName] = {};
        var listener = function (event, intersection) {
            if (intersection === void 0) { intersection = {}; }
            return _this.raycast(event, object3D, handler);
        };
        var listeners = this.listeners[eventName];
        var hashedHandler = hashFunc(handler);
        listeners[hashedHandler] = listener;
        this.element.addEventListener(eventName, listener);
    };
    Raycaster.prototype.off = function (eventName, handler) {
        var hashedHandler = hashFunc(handler);
        var listeners = this.listeners[eventName];
        if (listeners) {
            var listener = listeners[hashedHandler];
            if (listener) {
                this.element.removeEventListener(eventName, listener);
                listeners[hashedHandler] = undefined;
            }
        }
    };
    return Raycaster;
}());
exports.default = Raycaster;
