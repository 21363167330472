<template>
  <div class="page page--privacy page--opaque">
    <Header />

    <div class="container container--narrow">
      <div class="privacy-content">
        <h2>Privacy Policy</h2>
        <p>Privacy policy goes here...</p>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "../partials/Header.vue";

export default {
  components: {
    Header,
  },
};
</script>