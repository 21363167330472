<template>
  <div class="underlined-text" :class="uppercase && 'underlined-text--uppercase'">
    <slot></slot>
  </div>
</template>

<script>
  export default {
    props: ["uppercase"]
  };
</script>