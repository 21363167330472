import * as THREE from 'three';


export function generateCircle( radius, segments ){
    return new THREE.CircleBufferGeometry(radius, segments, segments);
}

export function generateSphere( radius, segments ){
    return new THREE.SphereBufferGeometry(radius, segments, segments);
}
