import anime from 'animejs';


export function lookAtPoint( from, to, callback ){

  const animation = anime({
    targets: from,
    easing: 'linear',
    elasticity: 0,
    duration: 500,
    complete: () => {
      if( callback ) callback();
    },
    x: to.x,
    y: to.y,
    z: to.z,

	});

	return animation;

}

export function popIn( mesh, callback ){ /* work in progress */

	const orginalPosition = mesh.position.clone();
	mesh.position.copy().normalize().addScaledVector(orginalPosition.normalize(), 1)

	mesh.position.z -= 1;
  const animation = anime({
    targets: mesh.position,
    easing: 'linear',
    duration: 3000,
    delay: 3000,
    complete: () => {
      if( callback ) callback();
    },
    x: orginalPosition.x,
    y: orginalPosition.y,
    z: orginalPosition.z,
	});
	return animation;
}