<template>
  <div class="globe" :class="{ loading: isLoading != false }">
    <Header />
    <div class="globe__controls" v-if="camera && !inBackground">
      <button
        :class="{
          'globe__control--disabled':
            camera.position.length() == settings.minDistance,
        }"
        @click="zoom(false, $event)"
      >
        <span class="icon-plus"></span>
      </button>
      <button
        :class="{
          'globe__control--disabled':
            camera.position.length() == settings.maxDistance,
        }"
        @click="zoom(true, $event)"
      >
        <span class="icon-minus"></span>
      </button>
    </div>

    <a href="https://nirvana.lnk.to/Nevermind30thWE" target="_blank" rel="noopener noreferrer" class="globe-cta button button--brush">Get the album</a>

    <div ref="scene" id="globe"></div>
  </div>
</template>
<script>
//three.js
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { generateCircle, generateSphere } from "../../helpers/geometries.js";
import {
  generateImageMaterial,
  generateMarkerMaterial,
  generateMarkerRingMaterial,
} from "../../helpers/materials.js";
import Raycaster from "../../helpers/raycaster";
import { lookAtPoint } from "../../helpers/animations";
import Header from "../partials/Header.vue";

export default {
  data() {
    return {
      settings: {
        rotate: true,
        radius: 0.55, // mobile = 0.55, desktop 1366 = 0.45
        delta: 0.005,
        segments: 64,
        minDistance: 0.75,
        maxDistance: 2,
      },

      mouse: {
        x: null,
        y: null,
      },

      // Tracks current intersect for some hover states
      currentIntersect: null,

      animation: null,

      camera: null,
      scene: null,
      renderer: null,
      controls: null,
      raycaster: null,

      meshes: null,
      lights: null,
      helpers: null,

      ready: false /* globe is ready for pins!! */,

      images: {
        //use mixing await this.loadImages to load and convert to object
        land: require("../../assets/images/globe-land-color.jpg"),
      },
    };
  },

  components: {
    Header,
  },

  props: ["inBackground", "isLoading", "regions"],

  watch: {
    inBackground: function (newVal, oldVal) {
      if (!newVal) {
        this.start();
      } else if (!newVal) {
        this.reset();
      }
    },

    regions: function (newVal, oldVal) {
      if (newVal) {
        this.start();
      }
    },
  },

  created() {
    this.$emit("update-loading", true);

    THREE.DefaultLoadingManager.onStart = (url, itemsLoaded, itemsTotal) => {
      this.$emit("update:loading", true);
    };

    THREE.DefaultLoadingManager.onLoad = () => {
      this.renderer.render(this.scene, this.camera);
      this.ready = true;
      this.start();

      setTimeout(() => {
        /* wait for markers too */
        this.$emit("update:loading", false);
      }, 750);
    };

    THREE.DefaultLoadingManager.onError = (url) => {
      console.log("There was an error loading " + url);
    };
  },

  mounted() {
    this.buildGlobe();
    this.resize();
    window.addEventListener("resize", this.resize, false);

    document.addEventListener(
      "mousemove",
      (event) => {
        this.mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
        this.mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;
      },
      false
    );
  },

  methods: {
    start() {
      if (!this.ready || this.inBackground || this.controls || !this.regions)
        return;

      this.initControls();

      this.addMarkers(this.regions);

      this.update();
    },

    buildGlobe() {
      //element
      const el = this.$refs.scene;
      if (el.clientWidth > 1024)
        this.settings.radius = 0.45; /* a little smaller */

      // geometries
      const geometries = {};

      geometries.land = generateSphere(
        this.settings.radius + this.settings.delta,
        this.settings.segments
      );

      // materials
      const materials = {};

      materials.land = generateImageMaterial(
        this.images.land,
        this.images.landAlpha
      );

      // meshes
      this.meshes = {};
      this.meshes.earth = new THREE.Group();
      this.meshes.earth.rotation.y -= (Math.PI / 2) * 1.5;

      this.meshes.land = new THREE.Mesh(geometries.land, materials.land);

      this.meshes.markers = new THREE.Group(); /* add markers later */

      this.meshes.earth.add(
        this.meshes.land,
        this.meshes.markers
      );

      //lighting
      this.lights = new THREE.Group();
      const ambient = new THREE.AmbientLight(0xffffff, 1); //can link this time of day!!!!!!!!

      const point = new THREE.PointLight(0xffffff, 1);
      point.position.set(-1, 1, 2);
      this.lights.add(ambient, point);

      // camera
      this.camera = new THREE.PerspectiveCamera(
        45,
        el.clientWidth / el.clientHeight,
        0.1,
        1000
      );

      this.camera.position.z = 2;

      // scene
      this.scene = new THREE.Scene();
      this.renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });

      // raycaster
      this.raycaster = new Raycaster(this.camera, this.renderer.domElement);

      // add
      this.scene.add(this.lights, this.meshes.earth);

      //render
      this.renderer.physicallyCorrectLights = true;
      this.renderer.gammaFactor = 2.2;
      this.renderer.outputEncoding = THREE.sRGBEncoding; // using sRGBEncoding on material too!
      this.renderer.setSize(el.clientWidth, el.clientHeight);
      el.appendChild(this.renderer.domElement);
    },

    addMarkers(regions) {
      if (!regions || regions.length == 0) return; // stop

      Object.keys(regions).forEach((region) => {
        const marker = this.buildMarker(
          regions[region].latitude,
          regions[region].longitude
        );

        this.meshes.markers.add(marker);

        marker.userData.region = region;

        this.raycaster.on("pointerup", marker, this.clickMarker);
      });
    },

    buildMarker(lat, lon) {
      let marker;

      if (this.meshes.markers.children.length > 0) {
        marker = this.meshes.markers.children[0].clone();
      } else {
        marker = new THREE.Mesh(
          generateSphere(this.settings.delta, this.settings.segments / 2),
          generateMarkerMaterial()
        );
        const ring = new THREE.Mesh(
          generateCircle(this.settings.delta * 1.5, this.settings.segments / 4),
          generateMarkerRingMaterial()
        );
        ring.rotation.x = Math.PI / 2;
        marker.add(ring);
      }

      const latRad = lat * (Math.PI / 180);
      const lonRad = -lon * (Math.PI / 180);
      const r =
        this.settings.radius + this.settings.delta + this.settings.delta;
      marker.position.set(
        Math.cos(latRad) * Math.cos(lonRad) * r,
        Math.sin(latRad) * r,
        Math.cos(latRad) * Math.sin(lonRad) * r
      );
      marker.rotation.set(0.0, -lonRad, latRad - Math.PI * 0.5);
      marker.scale.set(1.5, 1.5, 1.5)
      return marker;
    },

    clickMarker(evt, intersect) {
      const marker = intersect ? intersect.object : this.notifications.marker;
      const point = new THREE.Vector3().setFromMatrixPosition(
        marker.matrixWorld
      );

      const post = this.regions[marker.userData.region]["posts"][0];

      this.lookAt(point, true, () => {
        setTimeout(() => {
          this.$router.push({ path: `/post/${post.id}` });
        }, 500);
      });
    },

    initControls() {
      this.controls = new OrbitControls(this.camera, this.renderer.domElement);
      this.controls.enableDamping = true;
      this.controls.enableZoom = true;
      this.controls.zoomSpeed = 0.5;
      // this.controls.dampingFactor = 2;
      this.controls.autoRotate = false; /* if not animating */
      this.controls.autoRotateSpeed = 1;
      this.controls.rotateSpeed = 0.5;
      this.controls.minDistance = this.settings.minDistance;
      this.controls.maxDistance = this.settings.maxDistance;
      this.controls.minPolarAngle = Math.PI / 8;
      this.controls.maxPolarAngle = (Math.PI / 8) * 6;

      this.controls.mouseButtons = {
        LEFT: THREE.MOUSE.ROTATE,
        MIDDLE: THREE.MOUSE.DOLLY,
        RIGHT: ''
      }

      addEventListener("pointerdown", (event) => this.pause(0));
      addEventListener("pointerup", (event) => this.pause(1500));
    },

    resize(evt) {
      var aspect = window.innerWidth / window.innerHeight;
      this.camera.aspect = aspect;
      this.camera.fov = 45 / ((aspect + 1) / 2);
      this.camera.updateProjectionMatrix();
      this.renderer.setSize(window.innerWidth, window.innerHeight);
    },

    reset(evt) {
      this.controls.reset();
    },

    zoom(out, evt) {
      const step = 0.25;
      const current = this.camera.position.length();
      const distance = out ? current + step : current - step;
      this.camera.position.x *= distance / current;
      this.camera.position.y *= distance / current;
      this.camera.position.z *= distance / current;
    },

    pause(time) {
      this.settings.rotate = false;
      if (this.timer) clearTimeout(this.timer);
      if (time && time > 0)
        this.timer = setTimeout(() => {
          this.settings.rotate = true;
        }, time);
    },

    lookAt(point, animate = true, callback = null) {
      //https://discourse.threejs.org/t/solved-rotate-camera-to-face-point-on-sphere/1676

      let camDistance = this.camera.position.length();

      if (!animate) {
        this.camera.position
          .copy(point)
          .normalize()
          .multiplyScalar(camDistance);
        this.controls.update();
        return;
      }

      // animate it
      const newPosition = this.camera.position.clone();
      newPosition.copy(point).normalize().multiplyScalar(camDistance);
      lookAtPoint(this.camera.position, newPosition, callback);
    },

    rotate() {
      if (!this.settings.rotate) return this.settings.rotate;
      this.meshes.earth.rotation.y += 0.001;
    },

    update() {
      requestAnimationFrame(this.update);
      if (this.inBackground) return;
      this.rotate();

      for (const object of this.meshes.markers.children) {
        object.scale.set(1.5, 1.5, 1.5)
      }

      this.raycaster.raycaster.setFromCamera(this.mouse, this.camera);
      if (this.meshes.markers.children.length) {
        const intersects = this.raycaster.raycaster.intersectObjects(
          this.meshes.markers.children
        );
        if (intersects.length) {
          this.currentIntersect = intersects[0];
          // Intersect can be both the ring and the marker itself
          if (this.currentIntersect.object.parent.type === 'Group') {
            this.currentIntersect.object.scale.set(3, 3, 3)
          }
        }
      }

      this.lights.quaternion.copy(this.camera.quaternion); // but hold the lights
      this.controls.update();
      this.renderer.render(this.scene, this.camera);
    },
  },
};
</script>
