import * as THREE from 'three'
import { vertexShader, fragmentShader, vertexImageShader, fragmentImageShader } from './shaders.js';

export const loadingManager = new THREE.LoadingManager( () => {
  console.log('LOADED');
});

const loader = new THREE.TextureLoader();

export function generateMarkerRingMaterial( color = 0x000000 ){
  const material = new THREE.MeshBasicMaterial( { color: color, side: THREE.DoubleSide, opacity: 0.25, transparent: true } );
  material.color.convertSRGBToLinear();
  return material;
}


export function generateMarkerMaterial( color = 0x4d4d4d ){
  const material = new THREE.MeshLambertMaterial( { color: color, side: THREE.FrontSide } );
  material.color.convertSRGBToLinear();
  return material;
}


export function generateLineMaterial( color = 0xffff00 ){
  const material = new THREE.LineBasicMaterial( { color: color } );
  material.color.convertSRGBToLinear();
  return material;
}

export function generateImageMaterial( texture, alpha = false, transparent = true ){
  texture = loader.load(texture);
  texture.encoding = THREE.sRGBEncoding;

  const material = new THREE.MeshLambertMaterial({
    map : texture,
    ...( alpha && { alphaMap : loader.load(alpha) } ),
    transparent : transparent,
  });

  material.map.minFilter = THREE.LinearFilter;
  return material;
}

export function generateImageTileMaterial( texture ){
  texture = loader.load(texture);
  texture.wrapS = THREE.RepeatWrapping;
  texture.wrapT = THREE.RepeatWrapping;
  texture.repeat.set( 3, 3 );
  texture.encoding = THREE.sRGBEncoding;

  const material = new THREE.MeshLambertMaterial({
    map : texture,
  });
  material.map.minFilter = THREE.LinearFilter;
  return material;
}


export function generateImageShaderMaterial( base, cover ){
  let baseT = loader.load(base);
  let coverT = loader.load(cover);

  let uniforms = { // with images
    layer1 : { type: "t", value: coverT },
    layer2 : { type: "t", value: baseT },
  }

  return new THREE.ShaderMaterial({
    uniforms: uniforms,
    fragmentShader: fragmentImageShader(),
    vertexShader: vertexImageShader(),
  });
}

export function generateShaderMaterial( base, cover ){

  let uniforms = { // with colours
    layer1 : {type: 'vec3', value: new THREE.Color(base)},
    layer2 : {type: 'vec3', value: new THREE.Color(cover)}
  }

  return new THREE.ShaderMaterial({
    uniforms: uniforms,
    fragmentShader: fragmentShader(),
    vertexShader: vertexShader(),
  });
}
